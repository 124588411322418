// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuCube{
    position: absolute;
    bottom: 3%;
    left: 50%;
    width: 11vw;
    height: 2.7vw;
    border-radius: 0.5vw;
    transform: translateX(-50%);
    z-index: 99;

}

ul{
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1.2vw;
    align-items: center;
    justify-content: center;
}

.navButton {
    width: 0.63vw;
    height: 0.63vw;
    border-radius: 1vw;
    cursor: pointer;
    transition: all 0.3s ease; 
}

.navButton:hover {
    transform: scale(1.4) translateY(-0.2vw);
}

.navText {
    position: fixed;
    top: 0;
    left: 50%;
    font-weight: 500;
    font-size: 0.01vw;
    color: black;
    font-family: 'Montserrat', sans-serif;
    display: none;
}

.navButton:hover .navText{
    display: block;
    transform: scale(60) translateY(-0.009vw);
}

@media (max-width: 1025px) {
    .menuCube {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/menu/menu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,WAAW;IACX,aAAa;IACb,oBAAoB;IACpB,2BAA2B;IAC3B,WAAW;;AAEf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,UAAU;IACV,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,qCAAqC;IACrC,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,yCAAyC;AAC7C;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".menuCube{\n    position: absolute;\n    bottom: 3%;\n    left: 50%;\n    width: 11vw;\n    height: 2.7vw;\n    border-radius: 0.5vw;\n    transform: translateX(-50%);\n    z-index: 99;\n\n}\n\nul{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    gap: 1.2vw;\n    align-items: center;\n    justify-content: center;\n}\n\n.navButton {\n    width: 0.63vw;\n    height: 0.63vw;\n    border-radius: 1vw;\n    cursor: pointer;\n    transition: all 0.3s ease; \n}\n\n.navButton:hover {\n    transform: scale(1.4) translateY(-0.2vw);\n}\n\n.navText {\n    position: fixed;\n    top: 0;\n    left: 50%;\n    font-weight: 500;\n    font-size: 0.01vw;\n    color: black;\n    font-family: 'Montserrat', sans-serif;\n    display: none;\n}\n\n.navButton:hover .navText{\n    display: block;\n    transform: scale(60) translateY(-0.009vw);\n}\n\n@media (max-width: 1025px) {\n    .menuCube {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
